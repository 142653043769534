import { useState } from 'react';
import './CSS/App.css';
import AboutMe from './Components/AboutMe';
import Experience from './Components/Experience';
import Projects from './Components/Projects';
import PaperResume from './Components/PaperResume';
import ContactMe from './Components/ContactMe';

function App() {
  const [selectedPage, setSelectedPage] = useState("About Me");
  return (
    <div className="flex">
      <div className='navbar'>
        <div className="navbar-item" onClick={()=>{setSelectedPage("About Me")}}>
          <p>About Me</p>
        </div>
        <div className="navbar-item" onClick={()=>{setSelectedPage("Experience")}}>
          <p>Experience</p>
        </div>
        <div className="navbar-item" onClick={()=>{setSelectedPage("Projects")}}>
          <p>Projects</p>
        </div>
        <div className="navbar-item" onClick={()=>{setSelectedPage("Paper Resume")}}>
          <p>Paper Resume</p>
        </div>
        <div className="navbar-item" onClick={()=>{setSelectedPage("Contact Me")}}>
          <p>Contact Me</p>
        </div>
      </div>
      <div className='content-container'>
        {
          selectedPage === "About Me" ? <AboutMe /> :
          selectedPage === "Experience" ? <Experience/> :
          selectedPage === "Projects" ? <Projects/> :
          selectedPage === "Paper Resume" ? <PaperResume/> :
          selectedPage === "Contact Me" ? <ContactMe/> :
          null
        }
      </div>
    </div>
  );
}

export default App;
