import React from 'react'
import '../CSS/Components/Experience.css'
import buckleMap from '../Media/map.png'


const Experience = () => {
  return (
    <div className='section-container'>
        <div className='Experience-Title-Container'>
            <h1>Experience</h1>
        </div>
        <div className='Experience-Container'>
            <div className='Experience-Container-Top'>
                <div className='Experience-Context'>
                    <div className='Experience-Company'>Buckle</div>
                    <div>Software Engineer Intern</div>
                    <div>June 2022 - August 2022</div>
                </div>
                <div className='Experience-Photo'>
                    <img src={buckleMap} alt="Map showing multiple routes across America" />
                </div>
            </div>
            <div className='Experience-Description'>
                <p>While at the Buckle, I Built a spring boot + react application that utilized D3.js to visualize FedEx
packages across the US. Also had to work with an AS400 with an SQL
database, accessed in the spring boot backend with Mybatis. Used IntelliJ as
an IDE and DBeaver as a way to test queries for the AS400.</p>
            </div>
        </div>
    </div>
  )
}

export default Experience