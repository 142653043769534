import React from 'react'
import '../CSS/Components/AboutMe.css'

import me from '../Media/resume_photov1.png'
const AboutMe = () => {
  return (
    <div className='grid section-container'>
        <div className="grid-2-col">
            <div id='Short-Description'>
                <h2>Hi, I'm <span>Jacob Dimmitt</span></h2>
                <p>A Self-Learner out of High School with Great Ambition</p>
            </div>
            <div id="Photo">
                <img src={me} alt="" />
            </div>
        </div>
        {/* <div id="Description">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita, quibusdam explicabo! Maiores earum adipisci atque quisquam impedit fugit? Unde ipsa sed eaque, vero asperiores quis quasi architecto eius illo, blanditiis nihil ex modi at optio neque? Ipsa itaque dolores id similique fugiat enim sapiente sit odio aperiam. Consectetur, quasi dolore.</p>
        </div> */}
    </div>
  )
}

export default AboutMe