import React, { useState } from 'react'
import '../CSS/Components/Projects.css'
import '../Projects/pathfindingVisualizer/Pathfinding-Visualizer/pathfinder.css'
import algorithmVisualizerPhoto from '../Media/AlgorithmVisualizer.png'
import chessWebsite from '../Media/chessWebsite.png'
import fccla from '../Media/fccla.jpg'
import PathfindingVisualizer from '../Projects/pathfindingVisualizer/Pathfinding-Visualizer/PathfindingVisualizer'

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(0);
  const projects = [
    {
      "Title": "Algorithm Visualizer",
      "Description": "A Visualizer made with React to visualize Djikstra's Single Source Shortest Path Algorithm with any number of nodes and edges.",
      "Photo": algorithmVisualizerPhoto,
      "Link": "//github.com/JacobDDoS/Graph-Algorithm-Visualizer"
    },
    {
      "Title": "Pathfinding Algorithm Visualizer",
      "Description": "",
      "Photo": "",
      "Link": "//github.com/JacobDDoS/Pathfinding-Visualizer"
    },
    {
      "Title": "Chapter FCCLA Website",
      "Description": "For my 2022-2023 school year, I participated in my local FCCLA's STAR competition by creating a website for the chapter. It was a lot of work as I didn't want to use any website builder and wanted to build it & host it from scratch. In the end, the frontend design was rushed so I will be redesigning it this year (it cannot be visited right now due to hosting costs, but the code on GitHub is still up). The project was made in coordination with Adan and Alex, both seniors at my high school and featured a Spring Boot backend and a React frontend.",
      "Photo": fccla,
      "Link": "//github.com/JacobDDoS/KHS-STAR-Project"
    },
    {
      "Title": "Chess Website",
      "Description": "As a break from other projects/tutorials, I created a simple website to be able to play chess. It uses React as a frontend framework to be able to create an 8x8 grid while recording the moves taken on the right. If you click the link above and see the GitHub repo, you can find in the README, a link to the website so you can play it yourself.",
      "Photo": chessWebsite,
      "Link": "//github.com/JacobDDoS/Chess-website"
    },
  ]
  return (
    <div className='section-container'>
        <h2 className='Projects-title'>Projects</h2>
        <div className='Projects-container'>
          <div className='Project'>
            {
              projects.map((project, idx)=> {
                if (idx !== selectedProject) {
                  return;
                }
                if (project.Title === "Pathfinding Algorithm Visualizer") {
                  return <>
                  <h2 className="Project-title" style={{"fontSize": "2em"}}>
                    <a href={project.Link}>{project.Title}</a>
                  </h2>
                    <PathfindingVisualizer/>
                  </>
                }
                return <>
                <div className='Project-Photo' key={idx}>
                  <a target="_blank" href={project.Link}>
                    <img src={project.Photo} alt={project.Description} />
                  </a>
                </div>
                <h2 className="Project-title">
                  <a href={project.Link}>{project.Title}</a>
                </h2>
                <h2 className="Project-description">{project.Description}</h2>
                </>
              })
            }
          </div>
          <div className='Project-Navbuttons'>
            {
              projects.map((project, idx) => {
                if (idx === selectedProject) {
                  return <div className="closed-circle">
                    
                  </div>
                }
                return <div className="open-circle" onClick={()=>{setSelectedProject(idx)}}>
                    
                </div>
              })
            }
          </div>
        </div>
    </div>
  )
}

export default Projects