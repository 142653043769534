import React from 'react'
import '../CSS/Components/ContactMe.css'
const ContactMe = () => {
  return (
    <div className='section-container'>
        <div id='contact-header'>
            <h1>Contact Me!</h1>
        </div>
        <div id='contact-at'>
            <h3>Phone Number: (308) 293-8885 </h3>
            <h3>Email: jacdimmitt@gmail.com</h3>
        </div>
    </div>
  )
}

export default ContactMe