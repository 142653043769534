import React from 'react'
import '../CSS/Components/PaperResume.css'

const PaperResume = () => {
  return (
    <div className='section-container'>
        <h2 className='Paper-Text'>Paper Resume</h2>
        <object data="/Media/Profile.pdf" type="application/pdf" width="100%" height="500px">
            <p>Unable to display PDF file. <a href="https://drive.google.com/file/d/1HV5gjxtaD44cv4RzYbNMp1k8C8Dxa323/view?usp=sharing">Download</a> instead.</p>
        </object>
    </div>
  )
}

export default PaperResume